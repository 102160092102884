import React from 'react'
import About from '../components/AboutScreen/About'

function AboutScreen() {
  return (
    <div>
      <About/>
    </div>
  )
}

export default AboutScreen
